var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
        { 'CampaignForm--Controls-Fixed': !_vm.isWidget },
        'SingleButton',
        { FormAdmin: _vm.isFormAdmin },
        { 'CampaignForm--Controls-Widget': _vm.isWidget },
      ],attrs:{"data-testid":"forms-steps"}},[_c(_setup.PriceQuickWin,{attrs:{"price":_vm.price,"label":_vm.label,"isWidget":_vm.isWidget,"isFormAdmin":_vm.isFormAdmin,"isLoading":_vm.isLoading}}),_vm._v(" "),_c(_setup.HaButton,{class:[
          'NextButton',
          { ValidatingButton: (!_vm.canClickNext || !_vm.validForm) },
        ],attrs:{"size":"large","icon-side":"right","loading":_vm.isLoading,"data-ux":`Forms_${_vm.formType}${_vm.isWidget ? 'Widget' : ''}_NextStep`,"data-test":"button-next-step"},on:{"click":function($event){return _setup.emit('on-button-click')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fa',{attrs:{"icon":"angle-right"}})]},proxy:true}])},[_vm._v("\n        "+_vm._s(_vm.buttonLabel)+"\n        ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }